export type ProgressTypes = {
  BAR: 'bar';
  RADIAL: 'radial';
  PERCENTAGE: 'percentage';
};

export const ProgressTypes: ProgressTypes = {
  BAR: 'bar',
  RADIAL: 'radial',
  PERCENTAGE: 'percentage',
};
