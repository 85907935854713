import api from '@/api/videos';

export default {
  namespaced: true,
  state: {
    videos: [],
    loading: false,
    error: null,
    currentVideo: null
  },
  mutations: {
    SET_VIDEOS(state, videos) {
      state.videos = videos;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
    SET_CURRENT_VIDEO(state, video) {
      state.currentVideo = video;
    }
  },
  actions: {
    async initializeVideo({ commit, rootState }) {
      commit('CLEAR_ERROR');
      commit('SET_LOADING', true);
      
      const id_corporation = rootState.auth.user?.id_corporation;
      if (!id_corporation) {
        console.error('Missing corporation ID');
        commit('SET_ERROR', 'Corporation ID required');
        throw new Error('Corporation ID required');
      }
    
      try {
        const response = await api.initializeVideo(id_corporation);
        commit('SET_CURRENT_VIDEO', response.data);
        return response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        commit('SET_ERROR', errorMessage);
        throw error;
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async getUploadUrl({ commit, rootState }, { id_video, filename, contentType, fileSize, id_course_module }) {
      try {
        const id_corporation = rootState.auth.user?.id_corporation;
        if (!id_corporation) {
          throw new Error('Corporation ID required');
        }

        if (!id_video) {
          throw new Error('Video ID required');
        }

        if (!id_course_module) {
          throw new Error('Course module ID required');
        }

        const response = await api.getUploadUrl({ 
          id_video,
          id_corporation,
          id_course_module,
          filename, 
          contentType,
          fileSize 
        });
        return response.data.uploadUrl;
      } catch (error) {
        commit('SET_ERROR', error.message);
        throw error;
      }
    },
    async uploadVideo({ commit }, { file, uploadUrl, onProgress }) {
      try {
        await api.uploadToS3(file, uploadUrl, onProgress);
      } catch (error) {
        commit('SET_ERROR', error.message);
        throw error;
      }
    },
    async startProcessing({ commit }, { id_video, id_course_module }) {
      try {
        await api.startVideoProcessing(id_video, id_course_module);
      } catch (error) {
        commit('SET_ERROR', error.message);
        throw error;
      }
    },
    async getMuxUploadUrl({ commit }, { id_video, id_corporation, id_course_module }) {
      try {
        const response = await api.getMuxUploadUrl({ 
          id_video,
          id_corporation,
          id_course_module
        });
        return response.data;
      } catch (error) {
        commit('SET_ERROR', error.message);
        throw error;
      }
    },
    async updateVideoAfterUpload({ commit }, { id_video, uploadData }) {
      try {
        commit('SET_LOADING', true);
        const response = await api.updateVideoAfterUpload(id_video, uploadData);
        commit('SET_LOADING', false);
        return response.data;
      } catch (error) {
        commit('SET_ERROR', error.message);
        commit('SET_LOADING', false);
        throw error;
      }
    },
    async getVideoByModuleId({ commit }, id_course_module) {
      try {
        const response = await api.getVideoByModuleId(id_course_module);
        if (response.data) {
          commit('SET_CURRENT_VIDEO', response.data);
        }
        return response;
      } catch (error) {
        commit('SET_ERROR', error.message);
        throw error;
      }
    },
    async checkUploadStatus({ commit }, uploadId) {
      try {
        const response = await api.checkUploadStatus(uploadId);
        return response.data;
      } catch (error) {
        commit('SET_ERROR', error.message);
        throw error;
      }
    }
  }
};
