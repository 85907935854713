import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css"
import './assets/tailwind.css' 
import '@fortawesome/fontawesome-free/css/all.css' // Add this line for Font Awesome
import { i18n } from './i18n'
import '@mux/mux-player'
import '@mux/mux-uploader'

const app = createApp(App)
const head = createHead()

const toastOptions = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
}

app.use(store)
app.use(router)
app.use(Toast, toastOptions)
app.use(i18n)
app.use(head)
app.mount('#app')
window.addEventListener('unhandledrejection', event => {
  console.error('Unhandled promise rejection:', event.reason);
});
